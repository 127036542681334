<template>
  <div class="page-box box">
    <!-- top -->
    <div class="top-box box">
      <!-- banner -->
      <!-- <div class="banner-box box">
        <img src="@/assets/img/school-banner.png" alt="">
      </div> -->
      <div class="banner-box box">
        <van-swipe
          class="swiper"
          :autoplay="4500"
          @change="onChange"
        >
          <template v-for="(item,index) in detailData.imgUrls || []">
            <van-swipe-item
              :key="index"
              class="swiper-item"
            >
              <van-image :src="item"></van-image>
            </van-swipe-item>
          </template>
          <template #indicator>
            <div class="custom-indicator box">
              <template v-for="(item,index) in detailData.imgUrls || []">
                <div
                  :key="index"
                  class="swiper-indicator"
                  :class="{'active-indicator': index === current}"
                >
                </div>
              </template>
            </div>
          </template>
        </van-swipe>
      </div>

      <div class="text-a">
        {{ detailData.schoolName || '--' }}
      </div>

      <div class="text-b">
        <van-icon
          class="text-icon"
          name="location"
          style="color:#51B464"
        />
        {{ detailData.addr || '--' }}
      </div>

      <div class="text-c">
        <van-icon
          class="text-icon"
          name="phone"
          style="color:#51B464"
        />
        {{ detailData.tel || '暂无' }}
      </div>

      <div
        v-if="detailData.classifyName"
        class="tag-box box"
      >
        <div class="tag-item">
          {{ detailData.classifyName || '--' }}
        </div>
      </div>
    </div>

    <!--  -->
    <div class="bottom-box box">
      <!-- tab -->
      <div class="tab-box">
        <van-tabs
          v-model="activeName"
          calss="tab-out-box"
          swipeable
          color="#51B464"
          title-inactive-color="#262626"
          title-active-color="#51B464"
        >
          <template v-for="item in tabList">
            <van-tab
              :key="item.name"
              class="van-tab-box"
              :title="item.title"
              :name="item.name"
            >
            </van-tab>
          </template>
        </van-tabs>
      </div>

      <div class="border-x"></div>

      <div class="list-box box">
        <!-- 课程列表 -->
        <div
          v-if="activeName == 'a'"
          class="tab-in-box tab-a box"
        >
          <div class="title">
            课程列表
          </div>
          <template v-if="detailData.courseVoList && detailData.courseVoList.length > 0">
            <template v-for="item in detailData.courseVoList || []">
              <div
                :key="item.courseNo"
                class="box"
                @click="goCourseDetail(item)"
              >
                <course-item :item="item"></course-item>
              </div>
            </template>
          </template>
          <van-empty
            v-else
            description="暂无课程"
          />
        </div>
        <!-- 机构简介 -->
        <div
          v-if="activeName == 'a'"
          class="tab-in-box tab-c box"
        >
          <div class="title">
            门店描述
          </div>
          <div class="content">
            {{ detailData.describation || '暂无数据' }}
          </div>
        </div>

        <!-- 其他分校 -->
        <div
          v-if="activeName == 'b'"
          class="tab-in-box tab-c box"
        >
          <div class="title">
            其他分校
          </div>
          <template v-if="detailData.schoolVoList && detailData.schoolVoList.length > 0">
            <template v-for="item in detailData.schoolVoList || []">
              <div
                :key="item.schoolNo"
                class="box"
                @click="goSchoolDetail(item)"
              >
                <school-item :item="item"></school-item>
              </div>
            </template>
          </template>
          <van-empty
            v-else
            description="暂无分校"
          />
        </div>

        <div
          v-if="activeName == 'c'"
          class="tab-in-box tab-c box"
        >
          <van-empty
            description="暂无活动"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "@/components/list-item/course-item.vue"
import SchoolItem from "@/components/list-item/school-item.vue"
export default {
  name: 'SchoolIndex',
  components: {
    CourseItem, SchoolItem
  },
  data () {
    return {
      current: 0,
      tabList: [
        {title: '课程列表', name: 'a'},
        {title: '其他分校', name: 'b'},
        {title: '活动列表', name: 'c'}
      ],
      activeName: 'a',
      detailData: {}
    }
  },
  computed: {
  },
  watch: {
    $route (oval, nval) {
      this.activeName = 'a'
      this.getSchoolDetail()
    }
  },
  created () {
    this.getSchoolDetail()
  },
  methods: {
    async getSchoolDetail () {
      let data = {
        id: this.$route.query.id || ''
      }
      let res = await this.$http.homeApi.schoolDetail(data)
      console.log(res, 'res详情')
      if (res.code === 200) {
        this.detailData = res.msg || {}
      }
    },
    goCourseDetail (item) {
      this.$router.push(`/school/courseDetail?id=${item.courseNo || ''}`)
    },
    goSchoolDetail (item) {
      this.$router.push(`/school/detail?id=${item.schoolNo || ''}`)
    },
    onChange(index) {
      this.current = index;
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #F5F5F5;
  .top-box {
    background: #fff;
    padding-bottom: 15px;
    .banner-box {
      width: 100%;
      height: 204px;
      margin-bottom: 8px;
      // img {
      //   display: block;
      //   width: 345px;
      //   height: 184px;
      //   margin: 0 auto;
      //   border-radius: 7px;
      // }
      .swiper {
        height: 100%;
        width: 100%;
        position: relative;
        text-align: center;
        line-height: 215px;
        .swiper-item {
          img {
            width: 100%;
            height: 100%;
            position: relative;
          }
        }
        .custom-indicator {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: center;
          height: 20px;
          line-height: 14px;
          cursor: pointer;
          .swiper-indicator {
            width: 10px;
            height: 3px;
            display: inline-block;
            margin: 0 3px;
            background: rgb(190,190,197);
            border-radius: 1.5px;
          }
          .active-indicator {
            background: #282734;
          }
        }
      }
    }
    .text-a {
      padding: 0 15px;
      line-height: 24px;
      font-weight: 500;
      font-size: 15px;
    }
    .text-c, .text-b {
      padding-left: 35px;
      padding-right: 15px;
      font-size: x-small;
      font-size: 11px;
      color: #646464;
      position: relative;
      .text-icon {
        position: absolute;
        left: 16px;
        top: 5px;
        font-size: 12px;
        color: #51B464;
      }
    }
    .text-b {
      line-height: 24px;
      margin-top: 10px;
    }
    .text-c {
      line-height: 16px;
       .text-icon {
        top: 2px;
      }
    }
    .tag-box {
      padding: 0 15px;
      .tag-item {
        line-height: 16px;
        background: #E8F7EF;
        color: #51B464;
        padding: 0 6px;
        font-size: 11px;
        font-size: x-small;
        border-radius:2px;
        float: left;
        margin-top: 10px;
      }
    }
  }

  // bottom
  .bottom-box {
    margin-top: 6px;
    .tab-in-box {
      padding: 15px;
      background: #fff;
      .title {
        line-height: 24px;
        font-size: 15px;
      }
    }
    .tab-b, .tab-c {
      margin-top: 6px;
    }
    .tab-c {
      .content {
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
        color: #646464;
      }
    }
  }
  .border-x {
    width: 100%;
    height: 1px;
    margin-top: -1px;
    // z-index: 9;
    background: #d9d9d9;
    position: relative;
  }
}
</style>
