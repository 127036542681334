<template>
  <div class="box">
    <div class="list-item-box box">
      <div class="img-box">
        <van-image :src="item.img">
          <template #error>
            图片加载失败
          </template>
        </van-image>
      </div>
      <div class="text-a">
        <div class="text">
          {{ item.courseName || '--' }}
        </div>
      </div>
      <div class="tag-box box">
        <div class="tag-item">
          {{ item.classifyName || '--' }}
        </div>
      </div>

      <div
        v-if="item.activity == '1'"
        class="text-c box"
      >
        <div class="tag-a tag">
          平台活动
        </div>
      </div>
      <div class="price-a price">
        ￥{{ parseFloat(item.fee || 0).toFixed(2) }}
      </div>
      <div class="price-b price">
        ￥{{ parseFloat(item.fee || 0).toFixed(2) }}
      </div>
    </div>
    <van-divider style="margin: 0;" />
  </div>
</template>

<script>
export default {
  name: 'CourseItem',
  components: {
  },
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isGlobalPage: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.list-item-box {
  padding-left: 154px;
  padding-bottom: 10px;
  min-height: 92px;
  .img-box {
    width: 144px;
    height: 72px;
    position: absolute;
    left: 0;
    top: 10px;
    background: #ccc;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
  .text-a {
    margin-top: 7px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    line-height: 14px;
    .text {
      // display: inline-block;
      font-size: 15px;
      line-height: 20px;
    }
  }
  // 标签
  .tag-box {
    margin-top: 10px;
    .tag-item {
      line-height: 16px;
      background: #E8F7EF;
      color: #51B464;
      padding: 0 6px;
      font-size: 11px;
      font-size: x-small;
      border-radius:2px;
      float: left;
    }
  }
  // 多色标签
  .text-c {
    margin-top: 10px;
    .tag {
      margin-top:2px;
      float: left;
      line-height: 16px;
      padding: 0 6px;
      font-size: x-small;
      font-size: 10px;
      color:#fff;
      margin-right: 6px;
      border-radius: 8px;
    }
    .tag-a {
      background: linear-gradient(90deg, #FF953F, #FB703B);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    .tag-b {
      background: linear-gradient(-90deg, #6A42F5, #A084FF);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .price-a {
    color: #D5423E;
    position: absolute;
    bottom: 26px;
    right: 0;
    font-size: 12px;
    line-height: 14px;
  }
  .price-b {
    color: #B9B9B9;
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 10px;
    font-size: x-small;
    line-height: 12px;
    text-decoration: line-through;
  }
}
</style>
