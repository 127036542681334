<template>
  <div class="box">
    <div class="list-item-box box">
      <div class="img-box">
        <van-image :src="item.img">
          <template #error>
            图片加载失败
          </template>
        </van-image>
      </div>
      <div class="text-a">
        <div
          v-show="item.activity == 1"
          class="tag-a tag"
        >
          平台活动
        </div>
        <!-- <div
          v-show="item.activity"
          class="tag-b tag"
        >
          门店活动
        </div> -->
        <div
          class="text"
          :class="{'indent': item.activity == 1}"
        >
          {{ item.schoolName || '--' }}
        </div>
      </div>
      <div class="addr-box">
        <van-icon
          class="location-icon"
          name="location"
          style="color:#51B464"
        />
        {{ item.addr || '--' }}
      </div>
      <div class="text-b box">
        <div class="tag-c">
          {{ item.classifyName || '--' }}
        </div>
        <div class="text">
          {{ item.distance == -1 ? '未知' : (item.distance || '0') + 'km' }}
        </div>
      </div>
    </div>
    <van-divider style="margin: 0;" />
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isGlobalPage: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.list-item-box {
  padding-left: 154px;
  padding-bottom: 10px;
  min-height: 92px;
  .img-box {
    width: 144px;
    height: 72px;
    position: absolute;
    left: 0;
    top: 10px;
    background: #ccc;
    .van-image {
      width: 100%;
      height: 100%;
    }
  }
  .text-a {
    margin-top: 6px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    .tag {
      position: absolute;
      left: 0;
      top:2px;
      float: left;
      line-height: 16px;
      padding: 0 6px;
      font-size: x-small;
      font-size: 10px;
      color:#fff;
      border-radius: 8px;
    }
    .tag-a {
      background: linear-gradient(90deg, #FF953F, #FB703B);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    .tag-b {
      background: linear-gradient(-90deg, #6A42F5, #A084FF);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    .text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 15px;
      line-height: 20px;
    }
    .indent {
      text-indent: 66px;
    }
  }
  .text-b {
    margin-top: 5px;
    .tag-c {
      padding: 0 4px;
      float: left;
      height: 14px;
      background: #E8F7EF;
      border-radius: 2px;
      color: #51B464;
      font-size: 12px;
      line-height:14px;

    }
    .text {
      float: right;
      line-height: 16px;
      color: #9EA5AD;
      font-size: 12px;
    }
  }
  .addr-box {
    font-size: x-small;
    font-size: 10px;
    line-height: 14px;
    color: #8a8a8a;
    margin-top: 5px;
    padding-left: 16px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .location-icon {
      position: absolute;
      left: 0;
      top: 0;
      line-height:12px;
      font-size: 12px;
      color: #51B464;
    }
  }
}
</style>
